import React from 'react';
import Button from '../components/Button';
import { Column, Container, Row } from '../components/Grid';
import Icon from '../components/Icon';
import Image from '../components/Image';
import Layout from '../components/Layout';
import OutboundLink from '../components/OutboundLink';
import Section from '../components/Section';
import Subfooter from '../components/Subfooter';
import Video from '../components/Video';
import Resource from '../components/Resource';
import { Link } from 'gatsby';
import bgImage from '../images/CPdetail-image-videostill-bkgd-Kim.jpg';
import '../styles/styles.scss';

const PACareerProfile = () => (
  <Layout title="Health Equity Careers | Pharmaceutical Executive">
    <Section className="about-mission">
      <Container>
        <Button
          link
          to="/career-profiles"
          className="individual-profile__return"
        >
          &#60; View All
        </Button>
        <h1 className="h1 text-bold">Health Equity Careers</h1>
      </Container>
    </Section>
    <Section className="pt-0">
      <Container fullWidth>
        <Row>
          <Column>
            <div
              className="career-profile__video-wrapper"
              style={{ backgroundImage: `url('${bgImage}')` }}
            >
              <div className="career-profile__link-container">
                <Link
                  className="career-profile__link"
                  to="/pharmaceutical-sciences"
                >
                  <Icon name="arrowleft" />
                </Link>
              </div>
              <div className="career-profile__video-container">
                <Video guidSrc="e2f0ff30-4987-4909-87d4-089ca10705f1" />
              </div>
              <div className="career-profile__link-container">
                <Link className="career-profile__link" to="/health-worker">
                  <Icon name="arrowright" />
                </Link>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pt-0 pb-0">
      <Container>
        <Row>
          <Column size={8}>
            <h2 className="h2 mt-1">Pharmaceutical Executive</h2>
            <p>
              Learn more about this career by exploring the in-depth career fact
              sheet and engage students with hands-on activities for any
              environment.
            </p>
            {/* Quick links */}
            <div className="individual-profile__links">
              Quick Links:&nbsp;
              <Button link to="#about">
                About
              </Button>
              |
              <Button link to="#evaluate">
                Evaluate Your Interest
              </Button>
              |
              <Button link to="#activate">
                Activate Your Interest
              </Button>
            </div>
            <div>
              <Resource
                title="About"
                description="The Pharmaceutical Executive provides leadership in a facility,
              ensuring efficient and effective care is given to all patients
              in an environment that promotes patient equity. Charlotte
              noticed health inequities around her as a child and was driven
              toward a career in which she can positively impact other
              people’s lives through better access to healthcare."
                actions={[
                  {
                    label: 'Career Profile',
                    type: 'dropdown',
                    actions: [
                      {
                        label: 'English',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-VPofHEPA.pdf',
                        type: 'download',
                        title: 'VP of HEPA Career Profile-English',
                      },
                      {
                        label: 'German',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-VPofHEPA_DE.pdf',
                        type: 'download',
                        title: 'VP of HEPA Career Profile-German',
                      },
                      {
                        label: 'Spanish',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-VPofHEPA_ES.pdf',
                        type: 'download',
                        title: 'VP of HEPA Career Profile-Spanish',
                      },
                      {
                        label: 'Portuguese',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-VPofHEPA_PR.pdf',
                        type: 'download',
                        title: 'VP of HEPA Career Profile-Portuguese',
                      },
                      {
                        label: 'Italian',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-VPofHEPA_IT.pdf',
                        type: 'download',
                        title: 'VP of HEPA Career Profile-Italian',
                      },
                      {
                        label: 'French',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-VPofHEPA_FR.pdf',
                        type: 'download',
                        title: 'VP of HEPA Career Profile-French',
                      },
                      {
                        label: 'Chinese',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-VPofHEPA_Chinese.pdf',
                        type: 'download',
                        title: 'VP of HEPA Career Profile-Chinese',
                      },
                      {
                        label: 'Japanese',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-VPofHEPA_Japanese.pdf',
                        type: 'download',
                        title: 'VP of HEPA Career Profile-Japanese',
                      },
                      {
                        label: 'Hindi',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-VPofHEPA_HINDI.pdf',
                        type: 'download',
                        title: 'VP of HEPA Career Profile-Hindi',
                      },
                      {
                        label: 'Russian',
                        path:
                          '/pdfs/career-profiles/Takeda-CareerProfile-VPofHEPA_RU.pdf',
                        type: 'download',
                        title: 'VP of HEPA Career Profile-Russian',
                      },
                    ],
                  },
                ]}
              />
            </div>

            <div className="individual-profile__evaluate mt-1">
              <h3 className="h3" id="evaluate">
                Evaluate Your Interest
              </h3>
              <p>
                Find out more about this career and see how it aligns with
                student interests.
              </p>
              <ul>
                <li>
                  A flexible attitude towards change and perspective is
                  necessary for adapting to whatever the day brings.
                </li>
                <li>
                  Communication skills are essential for developing successful
                  partnerships.
                </li>
                <li>
                  Curiosity fuels an inquisitive attitude, which helps solve
                  challenges and identify improvements.
                </li>
                <li>
                  A passion for the wellbeing of people and communities is
                  essential to success.
                </li>
              </ul>
            </div>

            <div className="individual-profile__activate">
              <Resource
                title="Activate Your Interests"
                description="Bring this career to life with a classroom activity that
              empowers students to identify health inequities in their own
              communities and promote health equity awareness. This activity
              is designed as a companion to the video profile."
                actions={[
                  {
                    label: 'Student Activation',
                    type: 'dropdown',
                    actions: [
                      {
                        label: 'English',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-BuildingaMovementofChange.pdf',
                        type: 'download',
                        title: 'VP of HEPA Student Activation-English',
                      },
                      {
                        label: 'German',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-BuildingaMovementofChange_DE.pdf',
                        type: 'download',
                        title: 'VP of HEPA Student Activation-German',
                      },
                      {
                        label: 'Spanish',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-BuildingaMovementofChange_ES.pdf',
                        type: 'download',
                        title: 'VP of HEPA Student Activation-Spanish',
                      },
                      {
                        label: 'Portuguese',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-BuildingaMovementofChange_PR.pdf',
                        type: 'download',
                        title: 'VP of HEPA Student Activation-Portuguese',
                      },
                      {
                        label: 'Italian',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-BuildingaMovementofChange_IT.pdf',
                        type: 'download',
                        title: 'VP of HEPA Student Activation-Italian',
                      },
                      {
                        label: 'French',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-BuildingaMovementofChange_FR.pdf',
                        type: 'download',
                        title: 'VP of HEPA Student Activation-French',
                      },
                      {
                        label: 'Chinese',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-BuildingaMovementofChange_Chinese.pdf',
                        type: 'download',
                        title: 'VP of HEPA Student Activation-Chinese',
                      },
                      {
                        label: 'Japanese',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-BuildingaMovementofChange_Japanese.pdf',
                        type: 'download',
                        title: 'VP of HEPA Student Activation-Japanese',
                      },
                      {
                        label: 'Hindi',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-BuildingaMovementofChange_HINDI.pdf',
                        type: 'download',
                        title: 'VP of HEPA Student Activation-Hindi',
                      },
                      {
                        label: 'Russian',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-BuildingaMovementofChange_RU.pdf',
                        type: 'download',
                        title: 'VP of HEPA Student Activation-Russian',
                      },
                    ],
                  },
                ]}
              />
            </div>
          </Column>
          {/* Side column */}
          <Column size={4} className="pl-3 pt-4 pr-3">
            <Image
              filename="global-image-headshot-Charlotte@2x.png"
              className="careerProfiles__headshot"
            />
            <div className="individual-profile__holder">
              <div className="individual-profile__quote">
                “My parents instilled in me at an early age that we are
                responsible for each other.”
                <div className="mt-1">Charlotte Owens, MD</div>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pt-0">
      <Container>
        <Row style={{ justifyContent: 'center' }}>
          <Column size={8}>
            <Image filename="global-languages-promo-box@2x.png" />
          </Column>
        </Row>
        <Row className="mt-2">
          <Column size={12}>
            <h3 className="h3">Additional Careers to Explore</h3>
            <p>
              Discover the many exciting STEM careers that help support health
              equity globally. Learn what inspires these diverse individuals by
              exploring the below Career Profiles.
            </p>
          </Column>
        </Row>
        <Row className="mt-2">
          <Column size={6} className="pr-1">
            <OutboundLink to="/manufacturing-scientist">
              <Image filename="CPdetail-image-careers-MfgScientst.jpg" />
            </OutboundLink>
            <h3 className="h3">Manufacturing Scientist</h3>
            <p>
              Look inside the lab of a scientist who supports manufacturing
              investigations and monitors process performance for an entire
              department.
            </p>
            <Button to="/manufacturing-scientist" className="button--link">
              Learn more <Icon name="arrowright" marginLeft />
            </Button>
          </Column>
          <Column size={6} className="pl-1">
            <OutboundLink to="/pharmaceutical-sciences">
              <Image filename="CPdetail-image-careers-AssocDir.jpg" />
            </OutboundLink>
            <h3 className="h3">
              Associate Director of Pharmaceutical Sciences
            </h3>
            <p>
              Learn how advanced technologies like robotics and artifical
              intelligence are being incorporated into the process of providing
              better healthcare to patients.
            </p>
            <Button to="/pharmaceutical-sciences" className="button--link">
              Learn more <Icon name="arrowright" marginLeft />
            </Button>
          </Column>
        </Row>
      </Container>
    </Section>

    <Subfooter
      className="subfooter__quote-careers-no-quotes charlotte-subfooter"
      textRight
      footerImage="CPdetail-charlotte-image-QuoteBox.jpg"
      footerText="Provide leadership, knowledge sharing, and education on best practices for health equity."
    />
  </Layout>
);

export default PACareerProfile;
